import React from "react";
import ErrorPage from "./components/ErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Only log errors that are not related to ResizeObserver
    if (
      error.message !==
      "ResizeObserver loop completed with undelivered notifications."
    ) {
      console.error("Error caught by ErrorBoundary:", error, errorInfo);

      // Send error to backend
      fetch(`${process.env.REACT_APP_API_URL}/api/report_error`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          error: {
            message: error.message,
            stack: error.stack,
            componentStack: errorInfo.componentStack,
            url: window.location.href,
            userAgent: navigator.userAgent,
            timestamp: new Date().toISOString(),
          },
        }),
      }).catch((err) => console.error("Failed to send error report:", err));
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
