import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Background,
  Controls,
  Handle,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow";
import { useNavigate } from "react-router-dom";
import "reactflow/dist/style.css";
import ErrorBoundary from "./ErrorBoundary";
import dagre from "dagre";
import Header from "./components/Header";
import posthog from "posthog-js";
import { track } from "@vercel/analytics";

const isYouTubeUrl = (url) => {
  return url.includes("youtube.com/watch") || url.includes("youtu.be/");
};

const CustomNode = memo(({ data, id }) => {
  const [width, setWidth] = useState(400);
  const nodeRef = useRef(null);
  const resizeRef = useRef(null);
  const [isRerollDialogOpen, setIsRerollDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const node = nodeRef.current;
    const resizeHandle = resizeRef.current;
    let startX;
    let startWidth;

    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      startX = e.clientX;
      startWidth = node.offsetWidth;
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
      const dx = e.clientX - startX;
      const newWidth = Math.max(200, startWidth + dx);
      setWidth(newWidth);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    resizeHandle.addEventListener("mousedown", onMouseDown);

    return () => {
      resizeHandle.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const handleLinkClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (data?.url) {
        console.log("Opening URL in extension:", data.url, id);
        const isYouTube = isYouTubeUrl(data.url);
        posthog.capture("content_opened", {
          contentId: id,
          contentType: isYouTube ? "video" : "article",
          url: data.url,
        });
        track("content_opened", {
          contentId: id,
          contentType: isYouTube ? "video" : "article",
          url: data.url,
        });
        window.postMessage(
          {
            type: "HERETIC_OPEN_URL",
            action: "openUrlInExtension",
            url: data.url,
            submissionId: id,
            userId: data.user?.id,
            contentType: isYouTube ? "video" : "article",
          },
          "*"
        );
      } else {
        console.error("Missing url:", data);
        const hasShownExtensionAlert = localStorage.getItem(
          "hasShownExtensionAlert"
        );
        if (!hasShownExtensionAlert) {
          setTimeout(() => {
            alert(
              "Please ensure you have installed Heretic.School's Chrome Extension, or contact us andrew@heretic.school"
            );
            localStorage.setItem("hasShownExtensionAlert", "true");
          }, 1000);
        }
      }
    },
    [data, id]
  );

  const handleRerollClick = (e) => {
    e.stopPropagation();
    setIsRerollDialogOpen(true);
  };

  const handleSubmitReroll = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSubmitting) return;
      setIsSubmitting(true);
      if (data?.onReroll) {
        data.onReroll(id, rejectReason);
        setIsRerollDialogOpen(false);
        setRejectReason("");
      }
      setIsSubmitting(false);
    },
    [id, rejectReason, isSubmitting, data]
  );

  if (!data || typeof data !== "object") {
    console.error("Invalid data for CustomNode:", data);
    return null;
  }

  return (
    <div
      ref={nodeRef}
      className="p-4 rounded-lg bg-gradient-to-r from-gray-800 to-gray-700 border-2 border-blue-500 text-white shadow-lg backdrop-filter backdrop-blur-sm flex flex-col items-center justify-center relative"
      style={{ width: `${width}px`, minWidth: "200px" }}
      onClick={() => data.onExpand(null, { id, data })}
    >
      {data.category && (
        <div className="flex flex-col items-center mb-2">
          <span className="text-xs font-semibold bg-blue-500 text-white px-2 py-1 rounded-full">
            {data.category}
          </span>
          {data.progress && (
            <div className="mt-1 text-xs text-gray-300">
              <span>Unit {data.progress.current_level + 1}</span>
              <span className="mx-1">•</span>
              <span>
                {data.progress.projects_completed} /{" "}
                {data.progress.projects_required} Projects
              </span>
              <div className="w-full bg-gray-700 rounded-full h-1 mt-1">
                <div
                  className="bg-blue-500 rounded-full h-1"
                  style={{
                    width: `${
                      ((data.progress.projects_completed %
                        data.progress.projects_required) /
                        data.progress.projects_required) *
                      100
                    }%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <strong className="text-lg text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 text-center">
        {data.label || data.title || "Untitled"}
      </strong>
      {data.expanded && (
        <>
          <p className="mt-2 text-center">
            <a
              href={data.url || "#"}
              onClick={handleLinkClick}
              className="text-blue-400 hover:text-blue-300 underline"
            >
              {data.url || "No URL provided"}
            </a>
          </p>
          <p className="mt-2 text-gray-300 text-center">
            {data.description || "No description available"}
          </p>
          <button
            onClick={handleRerollClick}
            className="mt-4 w-full px-4 py-2 bg-gradient-to-r from-yellow-500 to-orange-500 text-white font-bold rounded-lg hover:from-yellow-600 hover:to-orange-600 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
          >
            Re-roll Content
          </button>
        </>
      )}
      <div
        ref={resizeRef}
        className="absolute right-0 top-0 bottom-0 w-2 cursor-ew-resize bg-blue-500 opacity-50 hover:opacity-100"
      />
      <Handle type="source" position="bottom" id={`${id}-source`} />
      <Handle type="target" position="top" id={`${id}-target`} />
      {isRerollDialogOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="bg-white p-6 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4 text-gray-900">
              Re-roll Content
            </h2>
            <p className="mb-4 text-gray-700">
              Please provide a reason for re-rolling this content:
            </p>
            <textarea
              className="w-full p-2 border rounded text-gray-900"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              rows={4}
              onClick={(e) => e.stopPropagation()}
            />
            <div className="mt-4 flex justify-end">
              <button
                className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRerollDialogOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleSubmitReroll}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const ProjectNode = memo(({ data, id }) => {
  const [width, setWidth] = useState(400);
  const nodeRef = useRef(null);
  const resizeRef = useRef(null);
  const [isRerollDialogOpen, setIsRerollDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const node = nodeRef.current;
    const resizeHandle = resizeRef.current;
    let startX;
    let startWidth;

    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      startX = e.clientX;
      startWidth = node.offsetWidth;
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
      const dx = e.clientX - startX;
      const newWidth = Math.max(200, startWidth + dx);
      setWidth(newWidth);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    resizeHandle.addEventListener("mousedown", onMouseDown);

    return () => {
      resizeHandle.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const handleReroll = (e) => {
    e.stopPropagation();
    setIsRerollDialogOpen(true);
  };

  const submitReroll = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSubmitting) return;
      setIsSubmitting(true);
      if (data?.onReroll) {
        data.onReroll(id, rejectReason);
        setIsRerollDialogOpen(false);
        setRejectReason("");
      }
      setIsSubmitting(false);
    },
    [id, rejectReason, isSubmitting, data]
  );

  const project =
    typeof data.project === "string" ? JSON.parse(data.project) : data.project;
  const { title, description, instructions, category } = project || {};

  return (
    <div
      ref={nodeRef}
      className="bg-gradient-to-r from-purple-800 to-blue-950 rounded-lg p-6 shadow-xl border border-pink-500 text-white backdrop-filter backdrop-blur-sm relative"
      style={{ width: `${width}px`, minWidth: "200px" }}
    >
      {category && (
        <div className="flex flex-col items-center mb-2">
          <span className="text-xs font-semibold bg-blue-500 text-white px-2 py-1 rounded-full">
            {category}
          </span>
          {data.progress && (
            <div className="mt-1 text-xs text-gray-300">
              <span>Unit {data.progress.current_level + 1}</span>
              <span className="mx-1">•</span>
              <span>
                {data.progress.projects_completed} /{" "}
                {data.progress.projects_required} Projects
              </span>
              <div className="w-full bg-gray-700 rounded-full h-1 mt-1">
                <div
                  className="bg-pink-500 rounded-full h-1"
                  style={{
                    width: `${
                      ((data.progress.projects_completed %
                        data.progress.projects_required) /
                        data.progress.projects_required) *
                      100
                    }%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <h3
        className="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-300 cursor-pointer"
        onClick={() => data.onExpand(null, { id, data })}
      >
        {title || "Untitled Project"}
      </h3>
      {data.expanded && (
        <>
          <p className="mb-4 text-gray-300 text-sm">
            {description || "No description available"}
          </p>
          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2 text-pink-300">
              Instructions:
            </h4>
            <pre className="text-gray-300 text-sm whitespace-pre-wrap font-sans">
              {instructions || "No instructions available"}
            </pre>
          </div>
          {(!data.feedback || !data.feedback.feedback) && (
            <button
              onClick={handleReroll}
              className="mt-4 w-full px-4 py-2 bg-gradient-to-r from-yellow-500 to-orange-500 text-white font-bold rounded-lg hover:from-yellow-600 hover:to-orange-600 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
            >
              Re-roll Project
            </button>
          )}
          {data.feedback && data.feedback.feedback && (
            <div className="mt-4 bg-purple-900 bg-opacity-50 rounded-lg p-4">
              <h4 className="text-lg font-semibold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-300">
                Feedback:
              </h4>
              <p className="mb-3 text-gray-300 text-sm">
                {data.feedback.feedback}
              </p>
              <p className="mb-2 text-green-400 text-sm">
                <strong>Strength:</strong> {data.feedback.strength}
              </p>
              <p className="mb-2 text-yellow-400 text-sm">
                <strong>Area for Improvement:</strong> {data.feedback.weakness}
              </p>
            </div>
          )}
        </>
      )}
      <div
        ref={resizeRef}
        className="absolute right-0 top-0 bottom-0 w-2 cursor-ew-resize bg-pink-500 opacity-50 hover:opacity-100"
      />
      <Handle
        type="source"
        position="bottom"
        id={`${id}-source`}
        className="w-3 h-3 bg-pink-500"
      />
      <Handle
        type="target"
        position="top"
        id={`${id}-target`}
        className="w-3 h-3 bg-pink-500"
      />
      {isRerollDialogOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="bg-white p-6 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4 text-gray-900">
              Re-roll Project
            </h2>
            <p className="mb-4 text-gray-700">
              Please provide a reason for re-rolling this project:
            </p>
            <textarea
              className="w-full p-2 border rounded text-gray-900"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              rows={4}
              onClick={(e) => e.stopPropagation()}
            />
            <div className="mt-4 flex justify-end">
              <button
                className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRerollDialogOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={submitReroll}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const CentralNode = memo(({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!data) return null;

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const truncateText = (text) => {
    if (!text) return "Not set";
    return text.length > 100 ? text.slice(0, 100) + "..." : text;
  };

  return (
    <div
      className="p-4 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 border-2 border-white text-white shadow-lg backdrop-filter backdrop-blur-sm max-w-sm flex flex-col items-center justify-center cursor-pointer"
      onClick={data.onUpdateClick}
    >
      <div className="mb-2 text-center">
        <strong className="text-sm text-pink-300">Dream Job:</strong>
        <p className="text-lg font-bold">
          {isExpanded ? data.dreamjob : truncateText(data.dreamjob)}
        </p>
      </div>
      <div className="text-center">
        <strong className="text-sm text-green-300">Biggest Interest:</strong>
        <p className="text-lg font-bold">
          {isExpanded
            ? data.biggest_personal_interest
            : truncateText(data.biggest_personal_interest)}
        </p>
      </div>
      <button
        onClick={toggleExpand}
        className="mt-2 text-xs text-blue-400 underline hover:text-blue-300"
      >
        {isExpanded ? "Show Less" : "Show More"}
      </button>
      <Handle type="source" position="bottom" id="central-source" />
    </div>
  );
});

const DownloadNode = memo(() => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const hasSeenExtensionPrompt = localStorage.getItem(
      "hasSeenExtensionPrompt"
    );
    if (hasSeenExtensionPrompt) {
      setIsVisible(false);
    }
  }, []);

  const handleDismiss = (e) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem("hasSeenExtensionPrompt", "true");
    setIsVisible(false);
  };

  return (
    <div
      className={`p-4 rounded-lg bg-gradient-to-r from-green-500 to-emerald-600 border-2 border-white text-white shadow-lg backdrop-filter backdrop-blur-sm flex flex-col items-center justify-center relative transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <button
        onClick={handleDismiss}
        className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-red-500 text-white flex items-center justify-center hover:bg-red-600 transition-colors duration-300"
      >
        ×
      </button>
      <a
        href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage"
        target="_blank"
        rel="noopener noreferrer"
        className="px-6 py-3 bg-white text-emerald-600 font-bold rounded-lg hover:bg-emerald-50 transition-colors duration-300 shadow-md"
      >
        DOWNLOAD EXTENSION
      </a>
      <Handle type="target" position="bottom" id="download-target" />
    </div>
  );
});

// Define nodeTypes outside of any component
const nodeTypes = {
  customNode: CustomNode,
  projectNode: ProjectNode,
  centralNode: CentralNode,
  downloadNode: DownloadNode,
};

const UpdateInfoPopup = ({
  onClose,
  onSubmit,
  currentDreamJob,
  currentPersonalInterest,
}) => {
  const [dreamJob, setDreamJob] = useState(currentDreamJob);
  const [personalInterest, setPersonalInterest] = useState(
    currentPersonalInterest
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(dreamJob, personalInterest);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Update Your Information</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="dreamJob"
            >
              Dream Job
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="dreamJob"
              type="text"
              value={dreamJob}
              onChange={(e) => setDreamJob(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="personalInterest"
            >
              Personal Interest
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="personalInterest"
              type="text"
              value={personalInterest}
              onChange={(e) => setPersonalInterest(e.target.value)}
            />
          </div>
          <div className="flex justify-end">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              type="submit"
            >
              Update
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Graph = ({ session, user }) => {
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [centralNodeData, setCentralNodeData] = useState(null);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const loadGraphRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState(
    localStorage.getItem("graphBackgroundColor") || "#111827"
  );

  // Add check for user prop
  useEffect(() => {
    if (!user) {
      navigate("/auth");
      return;
    }
  }, [user, navigate]);

  const handleBackgroundColorChange = (e) => {
    const newColor = e.target.value;
    setBackgroundColor(newColor);
    localStorage.setItem("graphBackgroundColor", newColor);
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onNodeClick = useCallback(
    (_, node) => {
      if (!node) return;
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: {
                ...n.data,
                expanded: !n.data.expanded,
              },
            };
          }
          return n;
        })
      );
    },
    [setNodes]
  );

  const handleUpdateClick = useCallback(() => {
    setShowUpdatePopup(true);
  }, []);

  const handleUpdateSubmit = useCallback(
    (newDreamJob, newPersonalInterest) => {
      posthog.capture("profile_updated", {
        dreamJob: newDreamJob,
        personalInterest: newPersonalInterest,
      });
      track("profile_updated", {
        dreamJob: newDreamJob,
        personalInterest: newPersonalInterest,
      });
      fetch(`${process.env.REACT_APP_API_URL}/api/update_user_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          dreamJob: newDreamJob,
          personalInterest: newPersonalInterest,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setNodes((prevNodes) =>
              prevNodes.map((node) => {
                if (node.id === "central-node") {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      dreamjob: newDreamJob,
                      biggest_personal_interest: newPersonalInterest,
                      onUpdateClick: handleUpdateClick,
                    },
                  };
                }
                return node;
              })
            );
            setCentralNodeData({
              dreamjob: newDreamJob,
              biggest_personal_interest: newPersonalInterest,
            });
            setShowUpdatePopup(false);
            loadGraphRef.current();
          }
        })
        .catch((error) => {
          console.error("Error updating user info:", error);
          setHasError(true);
        });
    },
    [user.id, loadGraphRef, setNodes, handleUpdateClick]
  );

  const getLayoutedElements = (nodes, edges) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const getNodeDimensions = (node) => {
      if (node.type === "centralNode") {
        return { width: 300, height: 120 }; // Central node dimensions
      }
      return { width: 400, height: 150 }; // Regular node dimensions
    };

    // Set graph direction and spacing
    dagreGraph.setGraph({
      rankdir: "TB", // Top to bottom direction
      align: "UL", // Upper left alignment
      nodesep: 80, // Horizontal separation between nodes
      ranksep: 150, // Vertical separation between ranks
      edgesep: 80, // Edge separation
      marginx: 50, // Margin in x direction
      marginy: 50, // Margin in y direction
    });

    // First add central node to ensure it's at the top
    const centralNode = nodes.find((node) => node.type === "centralNode");
    if (centralNode) {
      const dimensions = getNodeDimensions(centralNode);
      dagreGraph.setNode(centralNode.id, dimensions);
    }

    // Then add all other nodes
    nodes.forEach((node) => {
      if (node.type !== "centralNode") {
        const dimensions = getNodeDimensions(node);
        dagreGraph.setNode(node.id, dimensions);
      }
    });

    // Add edges
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    // Run the layout
    dagre.layout(dagreGraph);

    // Get the positioned nodes
    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const dimensions = getNodeDimensions(node);

      return {
        ...node,
        position: {
          x: nodeWithPosition.x - dimensions.width / 2, // Center the node
          y: nodeWithPosition.y - dimensions.height / 2,
        },
      };
    });

    // Style the edges
    const layoutedEdges = edges.map((edge) => ({
      ...edge,
      type: "straight",
      animated: true,
      style: {
        stroke: "#6366f1",
        strokeWidth: 2,
        opacity: 0.8,
      },
      markerEnd: {
        type: "arrowclosed",
        color: "#6366f1",
        width: 20,
        height: 20,
      },
    }));

    return { nodes: layoutedNodes, edges: layoutedEdges };
  };

  const handleRerollContent = useCallback(
    (contentId, rejectReason) => {
      const numericId = contentId.split("-")[0];
      posthog.capture("content_rerolled", {
        contentId: numericId,
        reason: rejectReason,
      });
      track("content_rerolled", {
        contentId: numericId,
        reason: rejectReason,
      });

      // Find the rejected content node to get its details
      const rejectedNode = nodes.find((node) => node.id === contentId);
      const rejectedContent = rejectedNode
        ? {
            title: rejectedNode.data.label,
            description: rejectedNode.data.description,
            url: rejectedNode.data.url,
            category: rejectedNode.data.category,
          }
        : null;

      fetch(`${process.env.REACT_APP_API_URL}/api/reroll_content`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          contentId: numericId,
          rejectReason,
          userId: user.id,
          rejectedContent,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(
                errorData.error || `HTTP error! status: ${response.status}`
              );
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            loadGraphRef.current();
          } else {
            throw new Error(data.error || "Failed to reroll content");
          }
        })
        .catch((error) => {
          console.error("Error rerolling content:", error);
          alert("Failed to reroll content. Please try again.");
        });
    },
    [user?.id, nodes, loadGraphRef]
  );

  // Process nodes before setting them
  const processNodes = useCallback(
    (nodes) => {
      // Add the static download node
      const centralNode = nodes.find((node) => node.type === "centralNode");
      if (centralNode) {
        const downloadNode = {
          id: "download-node",
          type: "downloadNode",
          position: {
            x: centralNode.position.x,
            y: centralNode.position.y - 200, // Changed to exactly 200 pixels above
          },
          draggable: false, // Make it non-draggable
          data: {},
        };
        return [
          ...nodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              expanded: node.data.expanded || false,
              onExpand: onNodeClick,
              onReroll: handleRerollContent,
              onUpdateClick: handleUpdateClick,
              user: user,
            },
          })),
          downloadNode,
        ];
      }
      return nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          expanded: node.data.expanded || false,
          onExpand: onNodeClick,
          onReroll: handleRerollContent,
          onUpdateClick: handleUpdateClick,
          user: user,
        },
      }));
    },
    [onNodeClick, handleRerollContent, handleUpdateClick, user]
  );

  // Update loadGraphRef to use processNodes
  useEffect(() => {
    loadGraphRef.current = async () => {
      if (!user?.id) {
        console.log("Missing required data:", { userId: user?.id });
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/fetch_project_tree?userId=${user.id}&category=${activeCategory}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.nodes || !Array.isArray(data.nodes)) {
          throw new Error("Invalid data format - missing or invalid nodes");
        }

        const filteredNodes = data.nodes.filter((node) => {
          if (node.id === "central-node") return true;
          let category = null;
          if (node.type === "customNode") {
            category = node.data?.category?.toLowerCase();
          } else if (node.type === "projectNode") {
            category = node.data?.category?.toLowerCase();
          }
          return !activeCategory || category === activeCategory.toLowerCase();
        });

        const filteredNodeIds = new Set(filteredNodes.map((node) => node.id));
        const filteredEdges = data.edges.filter(
          (edge) =>
            filteredNodeIds.has(edge.source) && filteredNodeIds.has(edge.target)
        );

        // Process nodes with the new function
        const processedNodes = processNodes(filteredNodes);

        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(processedNodes, filteredEdges);

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      } catch (error) {
        console.error("Error:", error);
        setHasError(true);
      }
    };
  }, [user, activeCategory, processNodes, setNodes, setEdges]);

  // Load initial data and check setup
  useEffect(() => {
    const verifyUserData = async () => {
      if (!user?.id) {
        console.log("No user ID available");
        setIsLoading(false);
        return;
      }

      try {
        console.log("Verifying user data for:", user.id);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.error("Error response from server:", errorData);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Received user data:", data);

        // Check if setup is complete
        if (
          !data.exists ||
          !data.hasRecommendations ||
          !data.selectedCategories?.length
        ) {
          console.log(
            "User needs to complete setup - removing setupComplete flag"
          );
          localStorage.removeItem("setupComplete");
          navigate("/dashboard");
          return;
        }

        // Set categories if they exist
        if (data.selectedCategories?.length > 0) {
          console.log("Setting categories:", data.selectedCategories);
          setSelectedCategories(data.selectedCategories);
          setActiveCategory(data.selectedCategories[0]);
          localStorage.setItem("setupComplete", "true");
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error verifying user data:", error);
        setHasError(true);
        setIsLoading(false);
      }
    };

    verifyUserData();
  }, [user, navigate]);

  // Load graph when category changes
  useEffect(() => {
    if (activeCategory) {
      loadGraphRef.current();
    }
  }, [activeCategory]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (hasError) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-900">
        <div className="text-center p-8 bg-gray-800 rounded-lg shadow-xl">
          <h2 className="text-xl text-red-400 mb-4">
            Oops! Something went wrong.
          </h2>
          <p className="text-gray-300 mb-4">
            We're having trouble loading your graph.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!selectedCategories.length) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-900">
        <div className="text-center p-8 bg-gray-800 rounded-lg shadow-xl">
          <h2 className="text-xl text-gray-300 mb-4">No Categories Selected</h2>
          <p className="text-gray-400 mb-4">
            Please complete the initial setup to view your learning journey.
          </p>
          <button
            onClick={() => navigate("/dashboard")}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col">
      <Header />
      <div className="flex bg-gray-900">
        <div className="flex flex-1">
          {selectedCategories.map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`flex-1 px-4 py-3 text-center transition-colors duration-200 ${
                activeCategory === category
                  ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium"
                  : "bg-gray-800 text-gray-300 hover:bg-gray-700"
              }`}
              style={{ width: `${100 / selectedCategories.length}%` }}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <div className="flex-1" style={{ backgroundColor }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          fitView
          minZoom={0.2}
          maxZoom={4}
          defaultViewport={{ zoom: 1 }}
          nodesDraggable={true}
          elementsSelectable={true}
          snapToGrid={true}
          snapGrid={[15, 15]}
          defaultEdgeOptions={{
            type: "straight",
            animated: true,
            style: {
              stroke: "#6366f1",
              strokeWidth: 2,
              opacity: 0.8,
            },
            markerEnd: {
              type: "arrowclosed",
              color: "#6366f1",
              width: 20,
              height: 20,
            },
          }}
        >
          <Background
            variant="dots"
            gap={12}
            size={1}
            color="#2a2a2a"
            style={{ position: "absolute" }}
          />
          <div className="absolute bottom-20 right-4 flex flex-col gap-4 z-10">
            <div className="bg-gray-800 p-2 rounded-lg shadow-lg backdrop-blur-sm flex items-center gap-2">
              <label
                htmlFor="bgColor"
                className="text-white text-sm whitespace-nowrap"
              >
                Background:
              </label>
              <input
                type="color"
                id="bgColor"
                value={backgroundColor}
                onChange={handleBackgroundColorChange}
                className="w-8 h-8 rounded cursor-pointer"
              />
            </div>
          </div>
          <Controls
            style={{
              button: {
                backgroundColor: "rgba(26, 26, 26, 0.8)",
                color: "#fff",
                borderColor: "rgba(51, 51, 51, 0.8)",
                backdropFilter: "blur(4px)",
              },
            }}
          />
        </ReactFlow>
      </div>

      {showUpdatePopup && centralNodeData && (
        <UpdateInfoPopup
          onClose={() => setShowUpdatePopup(false)}
          onSubmit={handleUpdateSubmit}
          currentDreamJob={centralNodeData.dreamjob}
          currentPersonalInterest={centralNodeData.biggest_personal_interest}
        />
      )}
    </div>
  );
};

const GraphWithErrorBoundary = ({ session, user }) => {
  return (
    <ErrorBoundary
      fallback={
        <div className="h-screen flex items-center justify-center bg-gray-900">
          <div className="text-center p-8 bg-gray-800 rounded-lg shadow-xl">
            <h2 className="text-xl text-red-400 mb-4">
              Oops! Something went wrong.
            </h2>
            <p className="text-gray-300 mb-4">
              We're having trouble loading your graph.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Try Again
            </button>
          </div>
        </div>
      }
    >
      <Graph session={session} user={user} />
    </ErrorBoundary>
  );
};

export default GraphWithErrorBoundary;
