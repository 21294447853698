import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

const buttonClasses =
  "text-white px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ease-in-out whitespace-nowrap";

const mobileButtonClasses =
  "text-white px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ease-in-out whitespace-nowrap w-full text-left hover:bg-gray-700";

const Header = ({ onChangePasswordClick }) => {
  const navigate = useNavigate();
  const [session, setSession] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
      setIsMenuOpen(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleExtensionDownload = () => {
    window.open(
      "https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage",
      "_blank"
    );
    setIsMenuOpen(false);
  };

  const navigationItems = [
    {
      label: "Blog",
      to: "/blog",
      className: `${buttonClasses} bg-green-600 hover:bg-green-700`,
      mobileClassName: `${mobileButtonClasses} bg-green-600`,
      condition: true,
    },
    {
      label: "Transcript",
      to: "/transcript",
      className: `${buttonClasses} bg-gray-700 hover:bg-gray-600`,
      mobileClassName: `${mobileButtonClasses} bg-gray-700`,
      condition:
        window.location.pathname === "/home" ||
        window.location.pathname === "/dashboard",
    },
    {
      label: "Manifesto",
      href: "https://x.com/garberchov/status/1866214878731747735",
      className: `${buttonClasses} bg-amber-500 hover:bg-amber-600`,
      mobileClassName: `${mobileButtonClasses} bg-amber-500`,
      condition: true,
      isExternal: true,
    },
    {
      label: "Download Extension",
      onClick: handleExtensionDownload,
      className: `${buttonClasses} bg-blue-500 hover:bg-blue-600`,
      mobileClassName: `${mobileButtonClasses} bg-blue-500`,
      condition: true,
    },
  ];

  const authItems = session
    ? [
        {
          label: "Sign Out",
          onClick: handleSignOut,
          className: `${buttonClasses} bg-indigo-500 hover:bg-indigo-600`,
          mobileClassName: `${mobileButtonClasses} bg-indigo-500`,
        },
        {
          label: "Plans",
          to: "/plans",
          className: `${buttonClasses} bg-purple-500 hover:bg-purple-600`,
          mobileClassName: `${mobileButtonClasses} bg-purple-500`,
        },
      ]
    : [
        {
          label: "Sign In",
          to: "/login",
          className: `${buttonClasses} bg-slate-600 hover:bg-slate-700`,
          mobileClassName: `${mobileButtonClasses} bg-slate-600`,
        },
        {
          label: "Sign Up",
          to: "/login",
          className: `${buttonClasses} bg-indigo-500 hover:bg-indigo-600`,
          mobileClassName: `${mobileButtonClasses} bg-indigo-500`,
        },
        {
          label: "Plans",
          to: "/plans",
          className: `${buttonClasses} bg-purple-500 hover:bg-purple-600`,
          mobileClassName: `${mobileButtonClasses} bg-purple-500`,
        },
      ];

  const renderNavigationItem = (item, isMobile = false) => {
    if (!item.condition && item.condition !== undefined) return null;

    const className = isMobile ? item.mobileClassName : item.className;

    if (item.isExternal) {
      return (
        <a
          key={item.label}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          onClick={() => isMobile && setIsMenuOpen(false)}
        >
          {item.label}
        </a>
      );
    }

    if (item.to) {
      return (
        <Link
          key={item.label}
          to={item.to}
          className={className}
          onClick={() => isMobile && setIsMenuOpen(false)}
        >
          {item.label}
        </Link>
      );
    }

    return (
      <button key={item.label} onClick={item.onClick} className={className}>
        {item.label}
      </button>
    );
  };

  return (
    <header className="bg-gray-900 shadow-lg relative z-50">
      <nav className="container mx-auto px-4 h-16">
        <div className="flex justify-between items-center h-full">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src="/heretic_school_logo.png"
              alt="Heretic.School"
              className="h-8 w-auto"
            />
            <span className="text-xl font-bold text-white">Heretic.School</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-3">
            {navigationItems.map((item) => renderNavigationItem(item))}
            {authItems.map((item) => renderNavigationItem(item))}
          </div>

          {/* Hamburger Menu Button */}
          <button
            className="md:hidden text-white p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
      </nav>

      {/* Mobile Navigation Menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-16 left-0 right-0 bg-gray-900 border-t border-gray-800 shadow-xl">
          <div className="container mx-auto px-4 py-2 space-y-2">
            {navigationItems.map((item) => renderNavigationItem(item, true))}
            {authItems.map((item) => renderNavigationItem(item, true))}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
