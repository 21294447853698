import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import posthog from "posthog-js";
import { track } from "@vercel/analytics";

// Fixed list of available categories
const CATEGORIES = [
  "entrepreneurship",
  "education",
  "history",
  "astronomy",
  "political science",
  "economics",
  "geography",
  "earth sciences",
  "mathematics",
  "literature",
  "computer science",
  "law",
  "physics",
  "engineering",
  "medicine",
  "communications",
  "chemistry",
  "cultural studies",
  "arts",
  "finance",
  "philosophy",
  "psychology",
  "biology",
  "urban planning",
  "architecture",
  "physical fitness",
];

const Form = ({ user }) => {
  const [formData, setFormData] = useState({
    age: "",
    dreamjob: "",
    biggestPersonalInterest: "",
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [recommendations, setRecommendations] = useState(null);
  const [isLoadingRecommendations, setIsLoadingRecommendations] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkExistingData = async () => {
      console.log("Starting checkExistingData");
      if (!user?.id) {
        console.log("No user ID found, redirecting to login");
        navigate("/login");
        setIsLoading(false);
        return;
      }

      try {
        console.log("Checking existing data for user:", user.id);
        const submissionResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );

        console.log("Response status:", submissionResponse.status);
        const submissionData = await submissionResponse.json();
        console.log("Received data:", submissionData);

        if (!submissionResponse.ok) {
          throw new Error(
            `HTTP error! status: ${
              submissionResponse.status
            }, message: ${JSON.stringify(submissionData)}`
          );
        }

        // If we have both recommendations and categories, setup is complete
        if (
          submissionData.exists &&
          submissionData.hasRecommendations &&
          Array.isArray(submissionData.selectedCategories) &&
          submissionData.selectedCategories.length > 0
        ) {
          console.log("Setup complete, redirecting to graph");
          localStorage.setItem("setupComplete", "true");
          navigate("/home");
          return;
        }

        // If we're on /dashboard but don't have complete setup data, remove the setupComplete flag
        if (localStorage.getItem("setupComplete") === "true") {
          console.log(
            "Incomplete setup data found, removing setupComplete flag"
          );
          localStorage.removeItem("setupComplete");
        }

        // If we have categories but no recommendations, restore the categories
        if (
          Array.isArray(submissionData.selectedCategories) &&
          submissionData.selectedCategories.length > 0
        ) {
          console.log(
            "Restoring categories:",
            submissionData.selectedCategories
          );
          setSelectedCategories(submissionData.selectedCategories);
          setStep(2);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error checking existing data:", error);
        if (error.message.includes("401") || error.message.includes("403")) {
          console.log("Authentication error, redirecting to login");
          navigate("/login");
        } else {
          setError("Failed to check existing data. Please try again later.");
          setIsLoading(false);
        }
      }
    };

    checkExistingData();
  }, [navigate, user]);

  // Load recommendations when moving to step 2
  useEffect(() => {
    const loadRecommendations = async () => {
      if (step !== 2 || !user?.id) return;

      setIsLoadingRecommendations(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/recommend_categories`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }

        setRecommendations(data);
      } catch (error) {
        console.error("Error loading recommendations:", error);
        setError("Failed to load recommendations. Please try again.");
      } finally {
        setIsLoadingRecommendations(false);
      }
    };

    loadRecommendations();
  }, [step, user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitStep1 = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      posthog.capture("form_step1_submitted", formData);
      track("form_step1_submitted", formData);

      // Save initial form data
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/submit_form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
            age: formData.age,
            dreamjob: formData.dreamjob,
            biggestPersonalInterest: formData.biggestPersonalInterest,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      // Move to step 2 after successful submission
      setStep(2);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Failed to submit form. Please try again later.");
      posthog.capture("form_step1_error", { error: error.message });
      track("form_step1_error", { error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategorySelect = async () => {
    if (selectedCategories.length === 0) return;

    setIsLoading(true);
    try {
      console.log("Starting category submission with:", selectedCategories);

      // Save the selected categories
      console.log("Saving categories to user profile");
      const categoriesResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/categories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.id}`,
          },
          body: JSON.stringify({ categories: selectedCategories }),
        }
      );

      if (!categoriesResponse.ok) {
        throw new Error(
          `Failed to save categories: ${categoriesResponse.status}`
        );
      }

      console.log("Getting recommendations for each category");
      // Get recommendations for each category sequentially to avoid race conditions
      for (const category of selectedCategories) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/get_start_recommendation`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              category,
              userId: user?.id,
              age: formData.age,
              dreamjob: formData.dreamjob,
              biggestPersonalInterest: formData.biggestPersonalInterest,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to get recommendation for ${category}: ${response.status}`
          );
        }

        const result = await response.json();
        console.log(`Got recommendation for ${category}:`, result);
      }

      // Give a small delay to ensure all recommendations are saved
      await new Promise((resolve) => setTimeout(resolve, 1000));

      console.log("Verifying final data before navigation");
      // Final verification
      const verifyResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!verifyResponse.ok) {
        throw new Error(`Failed to verify data: ${verifyResponse.status}`);
      }

      const verifyData = await verifyResponse.json();
      console.log("Final verification data:", verifyData);

      if (
        !verifyData.exists ||
        !verifyData.hasRecommendations ||
        !verifyData.selectedCategories?.length
      ) {
        console.error("Verification failed:", verifyData);
        throw new Error("Data verification failed");
      }

      // Set setup completion flag
      localStorage.setItem("setupComplete", "true");

      console.log("All checks passed, navigating to graph");
      navigate("/home");
    } catch (error) {
      console.error("Error in handleCategorySelect:", error);
      setError(
        "Failed to save categories and recommendations. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          <p className="mt-4 text-gray-300">Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600 p-4">
        <div className="w-full max-w-2xl bg-gray-800 bg-opacity-80 backdrop-blur-xl rounded-2xl shadow-2xl p-8">
          <h2 className="text-4xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-400">
            Heretic.School
          </h2>

          {step === 1 ? (
            <>
              <p className="text-gray-300 mb-6 text-center">
                Please provide detailed information to help us tailor your
                learning experience. The more specific you are, the better we
                can customize your journey.
              </p>
              <form onSubmit={handleSubmitStep1} className="space-y-6">
                <div>
                  <label
                    htmlFor="age"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Age
                  </label>
                  <input
                    type="number"
                    step="1"
                    id="age"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    placeholder="Enter your age - only whole numbers are accepted"
                    required
                    className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 shadow-glow"
                  />
                  <p className="mt-1 text-sm text-gray-400">
                    Your age helps us provide age-appropriate content and
                    recommendations.
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="dreamjob"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Student's Dream Job
                  </label>
                  <textarea
                    id="dreamjob"
                    name="dreamjob"
                    value={formData.dreamjob}
                    onChange={handleChange}
                    placeholder="Startup founder in the climate tech space, focusing on developing innovative carbon capture technologies. I'm passionate about combining entrepreneurship with environmental impact to address the urgent challenge of climate change."
                    required
                    rows={4}
                    className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 shadow-glow"
                  />
                  <p className="mt-1 text-sm text-gray-400">
                    Understanding your career aspirations helps us tailor
                    content to support your professional goals.
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="biggestPersonalInterest"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Student's Biggest Personal Interest
                  </label>
                  <textarea
                    id="biggestPersonalInterest"
                    name="biggestPersonalInterest"
                    value={formData.biggestPersonalInterest}
                    onChange={handleChange}
                    placeholder="The logistics of the Eastern Front in World War II, particularly how supply chains and resource management impacted military strategies. I'm fascinated by how logistical challenges shaped the outcome of major battles and the war as a whole."
                    required
                    rows={4}
                    className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 shadow-glow"
                  />
                  <p className="mt-1 text-sm text-gray-400">
                    The things that excite your brain are just as important as
                    your dream job - can be connected to your dream job, but
                    doesn't have to be.
                  </p>
                </div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-gray-400 text-white font-bold rounded-lg hover:from-blue-600 hover:to-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 transition duration-300 shadow-lg transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
                >
                  {isLoading ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    "Next Step"
                  )}
                </button>
              </form>
            </>
          ) : (
            <>
              <h2 className="text-4xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-400">
                Select Your Learning Categories
              </h2>
              <p className="text-gray-300 mb-8 text-center text-lg">
                Choose up to 3 categories that interest you the most
                {selectedCategories.length > 0 && (
                  <span className="ml-2 text-blue-400">
                    ({selectedCategories.length}/3 selected)
                  </span>
                )}
              </p>

              {/* Recommendations Section */}
              <div className="mb-12 bg-gray-900 bg-opacity-50 rounded-xl p-6">
                <h3 className="text-2xl font-semibold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-teal-300">
                  Recommended Categories
                </h3>
                {isLoadingRecommendations ? (
                  <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
                  </div>
                ) : recommendations && recommendations.recommendations ? (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                      {recommendations.recommendations.map((rec) => (
                        <div
                          key={rec.category}
                          className="flex flex-col space-y-4"
                        >
                          <button
                            onClick={() => {
                              if (selectedCategories.includes(rec.category)) {
                                setSelectedCategories(
                                  selectedCategories.filter(
                                    (c) => c !== rec.category
                                  )
                                );
                              } else if (selectedCategories.length < 3) {
                                setSelectedCategories([
                                  ...selectedCategories,
                                  rec.category,
                                ]);
                              }
                            }}
                            disabled={
                              selectedCategories.length >= 3 &&
                              !selectedCategories.includes(rec.category)
                            }
                            className={`
                              p-4 rounded-lg text-lg font-medium transition-all duration-200 transform hover:scale-105
                              ${
                                selectedCategories.includes(rec.category)
                                  ? "bg-gradient-to-r from-teal-500 to-teal-600 text-white shadow-lg scale-105"
                                  : selectedCategories.length >= 3
                                  ? "bg-gray-700 text-gray-400 cursor-not-allowed opacity-50"
                                  : "bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white"
                              }
                              border-2 
                              ${
                                selectedCategories.includes(rec.category)
                                  ? "border-teal-400"
                                  : "border-transparent"
                              }
                              flex items-center justify-center text-center
                              hover:shadow-xl
                            `}
                          >
                            <span className="capitalize">{rec.category}</span>
                            {selectedCategories.includes(rec.category) && (
                              <svg
                                className="w-5 h-5 ml-2 text-white"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            )}
                          </button>
                          <div className="bg-gray-800 bg-opacity-50 rounded-lg p-4 text-sm">
                            <div className="mb-3">
                              <h4 className="font-semibold text-blue-300 mb-1 flex items-center">
                                {rec.based_on === "dream_job" ? (
                                  <>
                                    <svg
                                      className="w-4 h-4 mr-1"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                      />
                                    </svg>
                                    Career Goal
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      className="w-4 h-4 mr-1"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                      />
                                    </svg>
                                    Personal Interest
                                  </>
                                )}
                              </h4>
                              <p className="text-gray-300">{rec.relevance}</p>
                            </div>
                            <div>
                              <h4 className="font-semibold text-blue-300 mb-1">
                                Key Benefits
                              </h4>
                              <ul className="list-disc list-inside text-gray-300">
                                {rec.key_benefits.map((benefit, index) => (
                                  <li key={index}>{benefit}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p className="text-gray-400 text-center py-4">
                    Unable to load recommendations. Please select from the
                    categories below.
                  </p>
                )}
              </div>

              <div className="border-t border-gray-700 pt-8">
                <h3 className="text-xl font-semibold mb-6 text-gray-300 text-center">
                  Or choose from all available categories:
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                  {CATEGORIES.map((category) => (
                    <button
                      key={category}
                      onClick={() => {
                        if (selectedCategories.includes(category)) {
                          setSelectedCategories(
                            selectedCategories.filter((c) => c !== category)
                          );
                        } else if (selectedCategories.length < 3) {
                          setSelectedCategories([
                            ...selectedCategories,
                            category,
                          ]);
                        }
                      }}
                      disabled={
                        selectedCategories.length >= 3 &&
                        !selectedCategories.includes(category)
                      }
                      className={`
                        p-4 rounded-lg text-lg font-medium transition-all duration-200 transform hover:scale-105
                        ${
                          selectedCategories.includes(category)
                            ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg scale-105"
                            : selectedCategories.length >= 3
                            ? "bg-gray-700 text-gray-400 cursor-not-allowed opacity-50"
                            : "bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white"
                        }
                        border-2 
                        ${
                          selectedCategories.includes(category)
                            ? "border-blue-400"
                            : "border-transparent"
                        }
                        flex items-center justify-center text-center
                        hover:shadow-xl
                      `}
                    >
                      <span className="capitalize">{category}</span>
                      {selectedCategories.includes(category) && (
                        <svg
                          className="w-5 h-5 ml-2 text-white"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      )}
                    </button>
                  ))}
                </div>
              </div>

              <div className="flex justify-center space-x-4">
                <button
                  onClick={() => setStep(1)}
                  className="px-8 py-4 rounded-lg text-xl font-bold bg-gray-700 text-gray-300 hover:bg-gray-600 transition-all duration-200"
                >
                  Back
                </button>
                <button
                  onClick={handleCategorySelect}
                  disabled={selectedCategories.length === 0 || isLoading}
                  className={`
                    px-8 py-4 rounded-lg text-xl font-bold
                    transition-all duration-200 transform
                    ${
                      selectedCategories.length === 0 || isLoading
                        ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                        : "bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700 hover:scale-105 hover:shadow-xl"
                    }
                    flex items-center space-x-2
                  `}
                >
                  {isLoading ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    <>
                      <span>Continue</span>
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
              <p className="text-center text-gray-400 mt-4 text-sm">
                <a
                  href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Please install the Chrome extension - it is necessary for the
                  app to work
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Form;
