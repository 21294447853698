import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";
import Header from "./Header";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [storedToken, setStoredToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Parse the token once and store it
  useEffect(() => {
    if (location.hash) {
      const fragment = location.hash.substring(1);
      const params = new URLSearchParams(fragment);
      const token = params.get("access_token");
      if (token) {
        console.log("Setting stored token");
        setStoredToken(token);
        // Store in sessionStorage as backup
        sessionStorage.setItem("reset_token", token);
      }
    }
  }, [location.hash]);

  // Use the stored token instead of parsing it every time
  const [type, accessToken] = useMemo(() => {
    const token = storedToken || sessionStorage.getItem("reset_token");
    return token ? ["recovery", token] : [null, null];
  }, [storedToken]);

  useEffect(() => {
    console.log("Current type:", type);
    console.log("Current token:", accessToken);

    const checkSession = async () => {
      try {
        if (accessToken) {
          // For password reset flow, we don't need to set the session immediately
          // Just verify the token is valid
          const {
            data: { user },
            error,
          } = await supabase.auth.getUser(accessToken);

          if (error) {
            console.error("Token verification error:", error);
            setError("Invalid or expired recovery link");
            sessionStorage.removeItem("reset_token");
            setTimeout(() => navigate("/auth"), 3000);
            return;
          }

          if (user) {
            console.log("Valid token for user:", user.email);
          }
        }
      } catch (error) {
        console.error("Error checking token:", error);
        setError("An error occurred while verifying your session");
        sessionStorage.removeItem("reset_token");
        setTimeout(() => navigate("/auth"), 3000);
      }
    };

    if (accessToken) {
      checkSession();
    }
  }, [accessToken, navigate, type]);

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setMessage("");

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw error;
      setMessage("Check your email for the password reset link");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setMessage("");

    try {
      if (!accessToken) {
        throw new Error("No valid reset token found");
      }

      // First verify the token is still valid
      const {
        data: { user },
        error: verifyError,
      } = await supabase.auth.getUser(accessToken);

      if (verifyError || !user) {
        throw new Error("Invalid or expired recovery link");
      }

      // Update the user's password
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) throw error;

      setMessage("Password reset successful! Redirecting to login...");
      sessionStorage.removeItem("reset_token");

      // Sign out any existing session
      await supabase.auth.signOut();

      setTimeout(() => navigate("/auth"), 2000);
    } catch (error) {
      console.error("Password reset error:", error);
      setError(error.message || "Failed to reset password");
    } finally {
      setLoading(false);
    }
  };

  // Show reset form if we have a token
  const showResetForm = !!accessToken;

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600">
        <div className="w-full max-w-md bg-gray-800 bg-opacity-80 backdrop-filter backdrop-blur-xl rounded-2xl shadow-2xl p-8">
          <h2 className="text-3xl font-bold mb-6 text-center text-white">
            {showResetForm ? "Reset Your Password" : "Request Password Reset"}
          </h2>

          {message && (
            <div className="mb-4 p-3 bg-green-500 text-white rounded">
              {message}
            </div>
          )}

          {error && (
            <div className="mb-4 p-3 bg-red-500 text-white rounded">
              {error}
            </div>
          )}

          <form
            onSubmit={showResetForm ? handleResetPassword : handleRequestReset}
          >
            {!showResetForm ? (
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            ) : (
              <div className="mb-4">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  minLength="6"
                  autoComplete="new-password"
                  placeholder="Enter your new password"
                />
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-500 text-white font-bold rounded-lg hover:from-blue-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            >
              {loading
                ? "Processing..."
                : showResetForm
                ? "Reset Password"
                : "Send Reset Link"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
