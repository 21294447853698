import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { posts } from "../posts";
import Header from "./Header";

const BlogPost = () => {
  const { slug } = useParams();
  const post = posts.find((p) => p.slug === slug);

  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  // Extract first paragraph for meta description
  const firstParagraph = post.description;

  return (
    <div className="bg-gray-950 min-h-screen">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <Helmet>
          <title>{post.title} - Heretic School Blog</title>
          <meta name="description" content={firstParagraph} />
          <meta
            property="og:title"
            content={`${post.title} - Heretic School Blog`}
          />
          <meta property="og:description" content={firstParagraph} />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={`https://hereticschool.com/blog/${post.slug}`}
          />
        </Helmet>

        <article className="max-w-4xl mx-auto bg-gray-800 bg-opacity-80 backdrop-blur-xl rounded-2xl shadow-2xl p-8 md:p-12">
          <div
            className="blog-content prose prose-lg prose-invert mx-auto"
            dangerouslySetInnerHTML={{
              __html: post.markdown,
            }}
          />
        </article>
      </div>
    </div>
  );
};

export default BlogPost;
