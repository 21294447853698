import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import ErrorBoundary from "../ErrorBoundary";
import posthog from "posthog-js";
import { track } from "@vercel/analytics";
import styled from "styled-components";

console.log("backendurl", process.env.REACT_APP_BACKEND_URL);
const DownloadPrompt = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const hasSeenExtensionPrompt = localStorage.getItem(
      "hasSeenExtensionPrompt"
    );
    if (hasSeenExtensionPrompt) {
      setIsVisible(false);
    }
  }, []);

  const handleDismiss = (e) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem("hasSeenExtensionPrompt", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="bg-gradient-to-r from-green-500 to-emerald-600 rounded-lg p-6 shadow-xl border-2 border-white text-white mb-8 relative">
      <button
        onClick={handleDismiss}
        className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-red-500 text-white flex items-center justify-center hover:bg-red-600 transition-colors duration-300"
      >
        ×
      </button>
      <a
        href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage"
        target="_blank"
        rel="noopener noreferrer"
        className="px-6 py-3 bg-white text-emerald-600 font-bold rounded-lg hover:bg-emerald-50 transition-colors duration-300 shadow-md block text-center"
      >
        DOWNLOAD EXTENSION
      </a>
    </div>
  );
};

const ContentBox = ({ content, onReroll, user, defaultExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [isRerollDialogOpen, setIsRerollDialogOpen] = useState(false);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (content?.url) {
      const isYouTube =
        content.url.includes("youtube.com/watch") ||
        content.url.includes("youtu.be/");
      posthog.capture("content_opened", {
        contentId: content.id,
        contentType: isYouTube ? "video" : "article",
        url: content.url,
      });
      track("content_opened", {
        contentId: content.id,
        contentType: isYouTube ? "video" : "article",
        url: content.url,
      });
      window.postMessage(
        {
          type: "HERETIC_OPEN_URL",
          action: "openUrlInExtension",
          url: content.url,
          submissionId: content.id,
          userId: user?.id,
          contentType: isYouTube ? "video" : "article",
        },
        "*"
      );
    }
  };

  return (
    <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/30 mb-4">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 mb-2">
            {content.title}
          </h3>
          {content.category && (
            <span className="inline-block text-xs font-semibold bg-blue-500/20 text-blue-300 px-3 py-1 rounded-full">
              {content.category}
            </span>
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="mt-4 space-y-4">
          <a
            href={content.url}
            onClick={handleLinkClick}
            className="block text-blue-400 hover:text-blue-300 underline break-all"
          >
            {content.url}
          </a>
          <p className="text-gray-300 leading-relaxed">{content.description}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsRerollDialogOpen(true);
            }}
            className="w-full px-4 py-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold rounded-lg hover:from-blue-600 hover:to-indigo-700 transition duration-300 transform hover:scale-105 shadow-lg"
          >
            Re-roll Content
          </button>
        </div>
      )}

      {isRerollDialogOpen && (
        <RerollDialog
          onClose={() => setIsRerollDialogOpen(false)}
          onSubmit={(reason) => {
            onReroll(content.id, reason);
            setIsRerollDialogOpen(false);
          }}
        />
      )}
    </div>
  );
};

const RerollDialog = ({ onClose, onSubmit }) => {
  const [reason, setReason] = useState("");

  return (
    <div
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="bg-gray-800 p-6 rounded-xl shadow-2xl border border-blue-500/20 w-full max-w-md">
        <h2 className="text-xl font-bold text-white mb-4">Re-roll Content</h2>
        <textarea
          className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Why would you like to re-roll this content?"
          rows={4}
        />
        <div className="flex justify-end gap-3 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => onSubmit(reason)}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const BorderGradient = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: ${(props) =>
    props.isShortAnswer
      ? "linear-gradient(45deg, rgba(147, 51, 234, 0.5), rgba(219, 39, 119, 0.5))"
      : "linear-gradient(45deg, rgba(139, 92, 246, 0.5), rgba(236, 72, 153, 0.5))"};
  border-radius: 0.75rem;
  z-index: -1;
  filter: blur(3px);
`;

const ProjectBox = ({ data, onReroll, defaultExpanded, className }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [isRerollDialogOpen, setIsRerollDialogOpen] = useState(false);
  const [submission, setSubmission] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [submissionMode, setSubmissionMode] = useState("text"); // 'text' or 'yap'
  const [mcAnswers, setMcAnswers] = useState({});
  const [mcResults, setMcResults] = useState(null);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  // Ensure type is properly identified
  const isShortAnswer = data?.type === "short_answer";

  // Request microphone access
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (e) => {
        setAudioChunks((prev) => [...prev, e.data]);
      };
    });
  }, []);

  const startRecording = () => {
    setAudioChunks([]);
    mediaRecorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    setIsRecording(false);
    const blob = new Blob(audioChunks, { type: "audio/wav" });
    setAudioURL(URL.createObjectURL(blob));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Convert audio to base64 if using voice submission
    let audioBase64 = "";
    if (audioURL) {
      const response = await fetch(audioURL);
      const blob = await response.blob();
      audioBase64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
      });
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/submit_project`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            submissionId: data.id,
            submission: submission,
            audio: audioBase64, // Send base64 audio data
          }),
        }
      );

      if (!response.ok) throw new Error("Submission failed");

      setSubmission("");
      setAudioURL("");
      window.location.reload();
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  const handleRerollClick = (e) => {
    e.stopPropagation();
    setIsRerollDialogOpen(true);
  };

  const submitReroll = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSubmitting) return;
      setIsSubmitting(true);

      if (onReroll) {
        onReroll(data.id, rejectReason);
        setIsRerollDialogOpen(false);
        setRejectReason("");
      }
      setIsSubmitting(false);
    },
    [data.id, rejectReason, isSubmitting, onReroll]
  );

  const handleMcSubmit = async () => {
    const results = data.multiple_choice_questions.map((q, i) => ({
      correct: q.correct_answer === mcAnswers[i],
      explanation: q.explanation,
    }));
    setMcResults(results);

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/submit_project`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          submissionId: data.id,
          mcAnswers: mcAnswers,
          isMcSubmission: true,
        }),
      });
    } catch (error) {
      console.error("MC submission error:", error);
    }
  };

  return (
    <div className={`relative ${className} p-[2px]`}>
      <BorderGradient isShortAnswer={isShortAnswer} />
      <div
        className="bg-gray-900/90 backdrop-blur-sm rounded-xl p-5 relative border border-purple-500/10 shadow-[0_0_25px_rgba(168,85,247,0.15)]"
        style={{
          boxShadow: isShortAnswer
            ? "0 0 25px rgba(168,85,247,0.15), inset 0 0 20px rgba(147,51,234,0.1)"
            : "0 0 25px rgba(236,72,153,0.15), inset 0 0 20px rgba(236,72,153,0.1)",
        }}
      >
        <div className="flex justify-between items-start mb-3">
          <h3
            className={`text-lg font-semibold ${
              isShortAnswer
                ? "text-transparent bg-clip-text bg-gradient-to-r from-purple-300 to-pink-300"
                : "text-transparent bg-clip-text bg-gradient-to-r from-violet-300 to-pink-300"
            }`}
          >
            {typeof data.title === "string" ? data.title : "Project"}
          </h3>
          <div className="flex gap-2">
            <button
              onClick={handleRerollClick}
              className={`text-gray-400 hover:text-gray-300 transition-all duration-300 transform hover:scale-110 ${
                isShortAnswer ? "hover:text-purple-400" : "hover:text-pink-400"
              }`}
            >
              <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
              className={`text-gray-400 hover:text-gray-300 transition-all duration-300 transform hover:scale-110 ${
                isShortAnswer ? "hover:text-purple-400" : "hover:text-pink-400"
              }`}
            >
              <svg
                className={`w-5 h-5 transform transition-transform ${
                  isExpanded ? "rotate-180" : ""
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        {isExpanded && (
          <div className="space-y-4">
            <div className="bg-gray-800/40 rounded-lg p-3 border border-purple-500/10">
              <p className="text-gray-300 text-sm">{data.description}</p>
            </div>

            <div className="bg-gray-800/40 rounded-lg p-3 border border-purple-500/10">
              <h4 className="text-base font-semibold text-purple-200 mb-1.5">
                Instructions:
              </h4>
              <div className="text-gray-300 text-sm whitespace-pre-wrap">
                {data.instructions}
              </div>
            </div>

            {data.rubric && typeof data.rubric === "object" && (
              <div className="bg-gray-800/40 rounded-lg p-3 border border-purple-500/10">
                <h4 className="text-base font-semibold text-purple-200 mb-1.5">
                  Rubric:
                </h4>
                {Object.entries(data.rubric).map(([criterion, description]) => (
                  <div key={criterion} className="mb-1.5 text-sm">
                    <span className="font-medium text-purple-200">
                      {criterion}:
                    </span>
                    <span className="text-gray-300 ml-1.5">
                      {typeof description === "string" ? description : ""}
                    </span>
                  </div>
                ))}
              </div>
            )}

            {(!data.feedback || !data.feedback.feedback) && (
              <form onSubmit={handleSubmit} className="mt-4 space-y-3">
                <div className="flex gap-2 mb-4">
                  <button
                    type="button"
                    onClick={() => setSubmissionMode("text")}
                    className={`px-4 py-2 rounded-lg ${
                      submissionMode === "text"
                        ? "bg-purple-500 text-white"
                        : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                    }`}
                  >
                    Text Answer
                  </button>
                  <button
                    type="button"
                    onClick={() => setSubmissionMode("yap")}
                    className={`px-4 py-2 rounded-lg ${
                      submissionMode === "yap"
                        ? "bg-pink-500 text-white"
                        : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                    }`}
                  >
                    Voice Chat Answer
                  </button>
                  {data.type === "short_answer" &&
                    data.multiple_choice_questions && (
                      <button
                        type="button"
                        onClick={() => setSubmissionMode("mc")}
                        className={`px-4 py-2 rounded-lg ${
                          submissionMode === "mc"
                            ? "bg-green-500 text-white"
                            : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                        }`}
                      >
                        Multiple Choice
                      </button>
                    )}
                </div>

                {submissionMode === "text" ? (
                  <>
                    <textarea
                      value={submission}
                      onChange={(e) => setSubmission(e.target.value)}
                      className="w-full h-28 px-3 py-2 bg-purple-900/30 text-white rounded-lg border border-purple-500/30 focus:border-purple-500 focus:ring-2 focus:ring-purple-500/50 transition-colors resize-none text-sm"
                      placeholder="Type your answer here..."
                      required
                    />
                    <button
                      type="submit"
                      className="w-full px-4 py-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white font-semibold rounded-lg hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 text-sm"
                    >
                      Submit Answer
                    </button>
                  </>
                ) : submissionMode === "mc" &&
                  data.multiple_choice_questions ? (
                  <div className="space-y-4">
                    {data.multiple_choice_questions.map((q, i) => (
                      <div key={i} className="bg-gray-800/40 p-4 rounded-lg">
                        <p className="font-medium text-gray-200 mb-2">
                          {q.question}
                        </p>
                        <div className="space-y-2">
                          {q.options.map((opt, j) => (
                            <label
                              key={j}
                              className="flex items-center space-x-2"
                            >
                              <input
                                type="radio"
                                name={`question-${i}`}
                                checked={mcAnswers[i] === j}
                                onChange={() =>
                                  setMcAnswers((prev) => ({ ...prev, [i]: j }))
                                }
                                className="form-radio text-purple-500"
                              />
                              <span className="text-gray-300">{opt}</span>
                            </label>
                          ))}
                        </div>
                        {mcResults && mcResults[i] && (
                          <div
                            className={`mt-2 text-sm ${
                              mcResults[i].correct
                                ? "text-green-400"
                                : "text-red-400"
                            }`}
                          >
                            {mcResults[i].explanation}
                          </div>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={handleMcSubmit}
                      className="w-full px-4 py-2 bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold rounded-lg hover:from-green-600 hover:to-teal-600 transition-all duration-300 transform hover:scale-105"
                    >
                      Submit Answers
                    </button>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className="flex items-center gap-4">
                      <button
                        type="button"
                        onClick={isRecording ? stopRecording : startRecording}
                        className={`p-3 rounded-full ${
                          isRecording
                            ? "bg-red-500 hover:bg-red-600"
                            : "bg-pink-500 hover:bg-pink-600"
                        } transition-colors`}
                      >
                        <svg
                          className="w-6 h-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          {isRecording ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          ) : (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                            />
                          )}
                        </svg>
                      </button>
                      {audioURL && (
                        <audio controls className="flex-1">
                          <source src={audioURL} type="audio/wav" />
                          Your browser does not support audio playback
                        </audio>
                      )}
                    </div>
                    <p className="text-sm text-gray-400">
                      {isRecording
                        ? "Recording... Click to stop"
                        : "Click microphone to start recording"}
                    </p>
                  </div>
                )}
              </form>
            )}

            {data.feedback && data.feedback.feedback && (
              <div className="mt-4 bg-purple-900/30 rounded-lg p-3 border border-purple-500/20">
                <h4 className="text-base font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-2">
                  Feedback:
                </h4>
                <p className="text-gray-300 mb-2 text-sm">
                  {data.feedback.feedback}
                </p>
                <p className="text-green-400 mb-1.5 text-sm">
                  <strong>Strength:</strong> {data.feedback.strength}
                </p>
                <p className="text-yellow-400 text-sm">
                  <strong>Area for Improvement:</strong>{" "}
                  {data.feedback.weakness}
                </p>
              </div>
            )}

            {submissionMode === "mc" && data.multiple_choice_questions && (
              <div className="space-y-4">
                {data.multiple_choice_questions.map((q, i) => (
                  <div key={i} className="bg-gray-800/40 p-4 rounded-lg">
                    <p className="font-medium text-gray-200 mb-2">
                      {q.question}
                    </p>
                    <div className="space-y-2">
                      {q.options.map((opt, j) => (
                        <label key={j} className="flex items-center space-x-2">
                          <input
                            type="radio"
                            name={`question-${i}`}
                            checked={mcAnswers[i] === j}
                            onChange={() =>
                              setMcAnswers((prev) => ({ ...prev, [i]: j }))
                            }
                            className="form-radio text-purple-500"
                          />
                          <span className="text-gray-300">{opt}</span>
                        </label>
                      ))}
                    </div>
                    {mcResults && mcResults[i] && (
                      <div
                        className={`mt-2 text-sm ${
                          mcResults[i].correct
                            ? "text-green-400"
                            : "text-red-400"
                        }`}
                      >
                        {mcResults[i].explanation}
                      </div>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleMcSubmit}
                  className="w-full px-4 py-2 bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold rounded-lg hover:from-green-600 hover:to-teal-600 transition-all duration-300 transform hover:scale-105"
                >
                  Submit Answers
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {isRerollDialogOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="bg-white p-6 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4 text-gray-900">
              Re-roll Project
            </h2>
            <p className="mb-4 text-gray-700">
              Please provide a reason for re-rolling this project:
            </p>
            <textarea
              className="w-full p-2 border rounded text-gray-900"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              rows={4}
              onClick={(e) => e.stopPropagation()}
            />
            <div className="mt-4 flex justify-end">
              <button
                className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRerollDialogOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={submitReroll}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UpdateProfileDialog = ({
  onClose,
  currentDreamJob,
  currentPersonalInterest,
  onUpdate,
}) => {
  const [dreamJob, setDreamJob] = useState(currentDreamJob);
  const [personalInterest, setPersonalInterest] = useState(
    currentPersonalInterest
  );

  return (
    <div
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-gray-800 p-8 rounded-xl shadow-2xl border border-purple-500/20 w-full max-w-md"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 mb-6">
          Update Your Profile
        </h2>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-300 mb-2 font-semibold">
              Dream Job
            </label>
            <input
              type="text"
              value={dreamJob}
              onChange={(e) => setDreamJob(e.target.value)}
              className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 transition-colors"
            />
          </div>
          <div>
            <label className="block text-gray-300 mb-2 font-semibold">
              Biggest Interest
            </label>
            <input
              type="text"
              value={personalInterest}
              onChange={(e) => setPersonalInterest(e.target.value)}
              className="w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 transition-colors"
            />
          </div>
        </div>
        <div className="flex justify-end gap-3 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => onUpdate(dreamJob, personalInterest)}
            className="px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg hover:from-purple-600 hover:to-blue-600 transition-colors shadow-lg"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

const ExtensionPrompt = ({ onDismiss }) => {
  return (
    <div className="mb-4 p-4 rounded-lg bg-gradient-to-r from-red-500/20 to-orange-500/20 border border-red-500/30">
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-red-400 mb-2">
            Install Chrome Extension
          </h3>
          <p className="text-gray-300">
            Get the most out of Heretic School by installing our Chrome
            extension. Track your progress and get personalized recommendations!
          </p>
          <div className="mt-3">
            <a
              href="https://chrome.google.com/webstore/detail/heretic-school/your-extension-id"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 bg-gradient-to-r from-red-500 to-orange-500 text-white rounded-lg hover:from-red-600 hover:to-orange-600 transition-colors shadow-lg"
            >
              Install Extension
            </a>
          </div>
        </div>
        <button
          onClick={onDismiss}
          className="ml-4 text-gray-400 hover:text-gray-300"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const FutureContent = () => (
  <div className="relative mt-8">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-900/50 to-gray-900 pointer-events-none" />
    <div className="space-y-4 opacity-30 blur-[2px] pointer-events-none">
      {[1, 2, 3].map((i) => (
        <div
          key={i}
          className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/30"
        >
          <div className="h-4 w-2/3 bg-gray-700/50 rounded mb-4" />
          <div className="h-3 w-1/2 bg-gray-700/50 rounded" />
        </div>
      ))}
    </div>
    <div className="absolute inset-x-0 bottom-0 flex justify-center pb-8">
      <div className="text-gray-400/70 text-sm font-medium px-4 py-2 rounded-full bg-gray-800/50 border border-gray-700/30">
        Complete current projects to unlock more content
      </div>
    </div>
  </div>
);

const Home = ({ session, user }) => {
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [content, setContent] = useState([]);
  const [backgroundColor] = useState("#111827"); // default dark background
  const [showExtensionPrompt, setShowExtensionPrompt] = useState(
    localStorage.getItem("extensionPromptDismissed") !== "true"
  );

  useEffect(() => {
    if (!user) {
      navigate("/auth");
      return;
    }

    const verifyUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("User data:", data); // Debug log

        if (!data.exists || !data.hasRecommendations) {
          localStorage.removeItem("setupComplete");
          navigate("/dashboard");
          return;
        }

        // Extract user info from either top level or submission
        const userInfo = {
          dreamjob: data.dreamjob || data.submission?.dreamjob || "",
          biggest_personal_interest:
            data.biggest_personal_interest ||
            data.submission?.biggest_personal_interest ||
            "",
          selectedCategories: data.selectedCategories || [],
        };

        setUserInfo(userInfo);
        console.log("Setting user info:", userInfo); // Debug log

        if (data.selectedCategories?.length > 0) {
          setSelectedCategories(data.selectedCategories);
          setActiveCategory(data.selectedCategories[0]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error verifying user data:", error);
        setHasError(true);
        setIsLoading(false);
      }
    };

    verifyUserData();
  }, [user, navigate]);

  const loadContent = useCallback(async () => {
    if (!user?.id || !activeCategory) return;

    try {
      setIsLoading(true);
      setHasError(false); // Reset error state
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/fetch_project_tree?userId=${user.id}&category=${activeCategory}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.id}`,
          },
        }
      );

      const data = await response.json();
      console.log("Content data:", data); // Debug log

      if (!response.ok || data.error) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (!Array.isArray(data.nodes)) {
        throw new Error("Invalid response format: nodes is not an array");
      }

      setContent(data.nodes);
    } catch (error) {
      console.error("Error loading content:", error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [user?.id, activeCategory]);

  useEffect(() => {
    if (activeCategory) {
      loadContent();
    }
  }, [activeCategory, loadContent]);

  const handleReroll = async (contentId, rejectReason) => {
    try {
      setIsLoading(true);
      const rejectedContent = content.find(
        (item) => item.id === contentId
      )?.data;

      if (!rejectedContent) {
        console.error("Could not find content to reroll");
        setHasError(true);
        return;
      }

      console.log("Rerolling with:", {
        contentId,
        rejectedContent,
        content: content,
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/reroll_content`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user?.id,
            submissionId: contentId,
            rejectReason,
            rejectedContent,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Reroll error response:", errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await loadContent();
    } catch (error) {
      console.error("Error rerolling content:", error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDismissExtensionPrompt = () => {
    localStorage.setItem("extensionPromptDismissed", "true");
    setShowExtensionPrompt(false);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (hasError) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-center p-8 bg-gray-800 rounded-xl shadow-2xl border border-red-500/20">
          <h2 className="text-2xl text-red-400 mb-4">
            Oops! Something went wrong.
          </h2>
          <p className="text-gray-300 mb-6">
            We're having trouble loading your content.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors shadow-lg"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen" style={{ backgroundColor }}>
      <Header />

      {/* Profile Section */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-gradient-to-br from-purple-600/20 to-blue-600/20 backdrop-blur-lg rounded-2xl p-8 mb-8 shadow-2xl border border-purple-500/20">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 mb-4">
                Your Learning Profile
              </h2>
              <div className="space-y-2">
                <p className="text-gray-300">
                  <span className="text-purple-400 font-semibold">
                    Dream Job:
                  </span>{" "}
                  {userInfo?.dreamjob}
                </p>
                <p className="text-gray-300">
                  <span className="text-purple-400 font-semibold">
                    Biggest Interest:
                  </span>{" "}
                  {userInfo?.biggest_personal_interest}
                </p>
              </div>
            </div>
            <button
              onClick={() => setShowUpdatePopup(true)}
              className="px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg hover:from-purple-600 hover:to-blue-600 transition-all duration-300 transform hover:scale-105 shadow-lg"
            >
              Update Profile
            </button>
          </div>
        </div>

        {/* Extension Prompt */}
        {showExtensionPrompt && (
          <ExtensionPrompt onDismiss={handleDismissExtensionPrompt} />
        )}

        {/* Category Tabs */}
        <div className="bg-gray-800/50 backdrop-blur-md rounded-xl overflow-hidden mb-8 shadow-lg border border-gray-700">
          <div className="flex">
            {selectedCategories.map((category) => (
              <button
                key={category}
                onClick={() => setActiveCategory(category)}
                className={`flex-1 px-6 py-4 text-center transition-all duration-300 ${
                  activeCategory === category
                    ? "bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold shadow-lg"
                    : "text-gray-300 hover:bg-gray-700/50"
                }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Content Section */}
        <div className="space-y-4">
          {content
            .filter(
              (item) =>
                item.data &&
                item.type !== "centralNode" && // Exclude central node
                (item.data.label ||
                  item.data.title ||
                  item.data.project ||
                  item.data.description)
            )
            .sort((a, b) => {
              // Sort nodes so that content appears before projects
              if (a.type === "customNode" && b.type === "projectNode")
                return -1;
              if (a.type === "projectNode" && b.type === "customNode") return 1;
              return 0;
            })
            .map((item, index, array) => {
              const isLastItem = index === array.length - 1;
              console.log("Rendering item:", item); // Debug log

              // Parse project data if it exists as a string
              let projectData = item.data.project
                ? typeof item.data.project === "string"
                  ? JSON.parse(item.data.project)
                  : item.data.project
                : null;

              // Check if it's a project node
              const isProject =
                item.type === "projectNode" ||
                (item.data.project && !item.data.label);

              if (isProject && projectData) {
                return (
                  <ProjectBox
                    key={item.id}
                    data={{
                      ...projectData,
                      title: projectData?.title || "Project",
                      type: projectData?.type || "comprehensive",
                      description: projectData?.description || "",
                      instructions: projectData?.instructions || "",
                      rubric: projectData?.rubric || {},
                      category: projectData?.category || item.data.category,
                      url: projectData?.url,
                    }}
                    onReroll={(rejectReason) =>
                      handleReroll(item.id, rejectReason)
                    }
                    defaultExpanded={isLastItem}
                    className="border-gradient"
                  />
                );
              }

              // Handle content nodes (non-project nodes)
              return (
                <ContentBox
                  key={item.id}
                  content={{
                    id: item.id,
                    title: item.data.label || item.data.title,
                    description: item.data.description,
                    url: item.data.url,
                    category: item.data.category,
                  }}
                  user={user}
                  onReroll={(rejectReason) =>
                    handleReroll(item.id, rejectReason)
                  }
                  defaultExpanded={isLastItem}
                />
              );
            })}
          <FutureContent />
        </div>
      </div>

      {showUpdatePopup && userInfo && (
        <UpdateProfileDialog
          onClose={() => setShowUpdatePopup(false)}
          currentDreamJob={userInfo.dreamjob}
          currentPersonalInterest={userInfo.biggest_personal_interest}
          onUpdate={async (dreamJob, personalInterest) => {
            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/update_user_info`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    userId: user.id,
                    dreamJob,
                    personalInterest,
                  }),
                }
              );

              if (!response.ok) throw new Error("Update failed");

              setUserInfo({
                ...userInfo,
                dreamjob: dreamJob,
                biggest_personal_interest: personalInterest,
              });
              setShowUpdatePopup(false);
              await loadContent();
            } catch (error) {
              console.error("Error updating profile:", error);
              setHasError(true);
            }
          }}
        />
      )}
    </div>
  );
};

const HomeWithErrorBoundary = ({ session, user }) => {
  return (
    <ErrorBoundary
      fallback={
        <div className="h-screen flex items-center justify-center bg-gray-900">
          <div className="text-center p-8 bg-gray-800 rounded-lg shadow-xl">
            <h2 className="text-xl text-red-400 mb-4">
              Oops! Something went wrong.
            </h2>
            <p className="text-gray-300 mb-4">
              We're having trouble loading your content.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Try Again
            </button>
          </div>
        </div>
      }
    >
      <Home session={session} user={user} />
    </ErrorBoundary>
  );
};

export default HomeWithErrorBoundary;
